(function() {
  const validCodeResponses = [
    {
      'data': {
        'codeType': 'IATA',
        'agencyName': 'Agency Name & Company LTD 1',
        'code': '12345679',
        'status': 'Active',
        'countryCode': 'AU',
        'countryName': 'Australia',
        'phoneCode': '61',
        'errorCode': '',
        'errorMessage': '',
        'states': [
          {
            'id': '01',
            'stateCode': 'AU_STATE_1',
            'stateName': 'AU STATE 1'
          },
          {
            'id': '02',
            'stateCode': 'AU_STATE_2',
            'stateName': 'AU STATE 2'
          }
        ],
        'cities': [
          {
            'stateCode': 'AU_STATE_1',
            'id': '01',
            'cityCode': 'SYD',
            'cityName': 'AU CITY 1'
          },
          {
            'stateCode': 'AU_STATE_1',
            'id': '02',
            'cityCode': 'MEL',
            'cityName': 'AU CITY 2'
          },
          {
            'stateCode': 'AU_STATE_2',
            'id': '03',
            'cityCode': 'BRI',
            'cityName': 'AU CITY 3'
          }
        ]
      }
    },
    {
      'data': {
        'codeType': 'TIDS',
        'agencyName': 'Agency Name & Company LTD 2',
        'code': '12345678',
        'status': 'Active',
        'countryCode': 'SIN',
        'countryName': 'Singapore',
        'phoneCode': '65',
        'errorCode': '',
        'errorMessage': '',
        'states': [
          {
            'id': '01',
            'stateCode': 'SIN_STATE_1',
            'stateName': 'SIN STATE 1'
          },
          {
            'id': '02',
            'stateCode': 'SIN_STATE_2',
            'stateName': 'SIN STATE 2'
          }
        ],
        'cities': [
          {
            'stateCode': 'SIN_STATE_1',
            'id': '01',
            'cityCode': 'SYD',
            'cityName': 'SIN CITY 1'
          },
          {
            'stateCode': 'SIN_STATE_1',
            'id': '02',
            'cityCode': 'MEL',
            'cityName': 'SIN CITY 2'
          },
          {
            'stateCode': 'SIN_STATE_2',
            'id': '02',
            'cityCode': 'MEL',
            'cityName': 'SIN CITY 3'
          }
        ]
      }
    },
    {
      'data': {
        'codeType': 'IATA',
        'agencyName': 'Agency Name & Company LTD 2',
        'code': '12345670',
        'status': 'Active',
        'countryCode': 'SIN',
        'countryName': 'Singapore',
        'phoneCode': '65',
        'errorCode': '',
        'errorMessage': '',
        'cities': [
          {
            'stateCode': 'SIN_STATE_1',
            'id': '01',
            'cityCode': 'SYD',
            'cityName': 'SIN CITY 1'
          },
          {
            'stateCode': 'SIN_STATE_1',
            'id': '02',
            'cityCode': 'MEL',
            'cityName': 'SIN CITY 2'
          },
          {
            'stateCode': 'SIN_STATE_2',
            'id': '02',
            'cityCode': 'MEL',
            'cityName': 'SIN CITY 3'
          }
        ]
      }
    },
  ];

  const invalidCodeResponse = {
    'data': {
      'errorCode': 'E0001',
      'errorMessage': 'Value does not exist'
    }
  };

  function validateCode(code) {
    const codeFound = validCodeResponses.find(codeResponse => codeResponse.data.code === code);

    return codeFound ? codeFound : invalidCodeResponse;
  }

  window.iataTidsService = {
    validateCode
  };
})();
